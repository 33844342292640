/* Fix issue with line clamp on safari - node child need to be inline - src https://stackoverflow.com/a/75985479 */
.line-clamp-fix {
  & > *:not(ul) {
    display: inline;

    &::after {
      content: " "; // add a space after each inline child element to make text readable
    }
  }

  &.rte_text_content > ul {
    display: -webkit-inline-flex;
    flex-direction: column;
    width: 100%;
  }
}
