.program-v2 {
  .button {
    border: 1px solid var(--color_button);
    white-space: nowrap !important;

    &.ghost:not(label) {
      &:active,
      &:focus,
      &:hover {
        background-color: transparent !important;
        border: 1px solid var(--color_button);
        color: var(--color_button) !important;
      }
    }

    &:focus,
    &.active:focus,
    &:has(> [type="radio"]:focus) {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    [type="radio"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  .btn-link {
    &,
    &:hover,
    &:focus,
    &:active {
    border: none;
    background: none;
    padding: 0;
    }
  }

  /* Time range slider */
  .multi-range-wrapper {
    .slider {
      position: relative;
      width: 100%;
      top: 1px;

      .track {
        position: relative;
        height: 1px;
        background: var(--color_text);
        width: 100%;
        z-index: 1;
      }
    }

    .thumb,
    .thumb::-webkit-slider-thumb {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
    }

    .thumb {
      pointer-events: none;
      position: relative;
      height: 0;
      width: 100%;
      outline: none;

      &.left { z-index: 3; }
      &.right { z-index: 4; }

      &::-webkit-slider-thumb {
        position: relative;
        background: var(--color_button);
        border-radius: 50%;
        cursor: pointer;
        height: 11px;
        width: 11px;
        margin-top: 4px;
        pointer-events: all;
      }
    }
  }

  /* Thematics checkbox */
  .search-filter {
    & > div:not(.dates-wrapper) div:last-child .button { margin-bottom: 0; }

    label > .checkbox  {
      margin: 0;

      label { display: block; }
    }
  }

  &.program-wrapper {
    .label {
      margin: 0 5px 5px 0;
      white-space: pre-wrap;
      text-align: left;
    }

    .date span {
      display: inline-block;

      &::first-letter { text-transform: capitalize; }
    }

    .date-separator {
      margin-left: -15px;
      margin-right: -15px;
      padding: 10px 15px;
      background-color: var(--color_body_bg);
      z-index: 10;
      margin-bottom: -10px;
      line-height: 1;
    }

    .program-filter-wrapper {
      margin-left: -15px;
      margin-right: -15px;
      padding: 10px 15px;
      background-color: var(--color_body_bg);
      z-index: 10;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.card-shadow {
        background-color: #fff;
        padding: 15px;
      }

      .form-group {
        margin-bottom: 0;
      }

      .search-bar {
        .form-control {
          padding-left: 40px;
        }

        .form-control + .filters-pane-button {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 3;
        }

        .button.button-small {
          padding: 0 1.2em;
          height: 35px;

          & > * {
            font-size: 13px !important;
            font-size: calc(var(--type_base_size)*.9) !important;
            line-height: 1 !important;
          }
        }

        .custom-addon {
          position: absolute;
          z-index: 7;
          height: 100%;
          padding: 6px 12px;
          font-size: 18px;
          font-weight: 400;
          display: flex;
          align-items: center;
          color: #555555;
        }
      }
    }

    .dates-wrapper {
      position: relative;

      .date-btn {
        .button::first-letter { text-transform: capitalize; }
        .button { margin: 1px 0; } /* Fix safari border issue */
      }

      #dates-container .button.button-small { display: block; }
    }

    .filters-summary-wrapper {
      .gradient {
        &.right::after,
        &.left::before {
          display: none !important;
        }
      }
    }

    .dates-wrapper,
    .filters-summary-wrapper {
      .gradient {
        scrollbar-width: none; /* Hide scrollbar on firefox same as ::-webkit-scrollbar display none for WebKit */

        &::-webkit-scrollbar { display: none; }

        &::after,
        &::before {
          content: "";
          position: absolute;
          top: 0;
          width: 80px;
          height: 100%;
          pointer-events: none;
          z-index: 2;
          opacity: 0;
          transition: opacity 0.25s ease-out;
        }

        &::after {
          right: 0;
          background: linear-gradient(90deg, transparent, var(--color_body_bg));
        }

        &::before {
          left: 0;
          background: linear-gradient(90deg, var(--color_body_bg), transparent);
        }

        &.right::after,
        &.left::before {
          opacity: 1;
        }
      }
    }

    .metadata-wrapper {
      margin-top: 20px;
      & + .metadata-wrapper { margin-top: 0; }
    }

    .loader-sticky-container {
      position: sticky;
      top: 50vh;
      z-index: 5;
      height: 0;
      overflow: visible;
      display:flex;
      width: 100%;
    }

    .loader-card {
      background: #FFF;
      line-height: 1;
      padding: 10px;
      margin: 0 auto;
      height: 50px;
      width: 50px;
    }

    @media only screen and (max-width: 768px) {
      .dates-wrapper {
        .gradient::after,
        .gradient::before {
          width: 40px;
          display: block;
        }
      }

      .filters-summary-wrapper {
        position: relative;

        .gradient {
          overflow: auto;

          &.right::after,
          &.left::before {
            width: 40px;
            display: block !important;
          }

          & > div { flex-wrap: nowrap; }

          button { white-space: nowrap !important; }
        }
      }
    }
  }

  &.add-to-my-calendar-modal {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    max-width: 90%;
    max-height: 95vh;
    padding: 0;
    margin: 0;

    .btn-block { margin: 0; }
    .btn-block:last-child { margin-left: 10px; }
  }
}

/* Filters slide pane */
.ReactModal__Body--open { overflow: hidden; }

.ReactModalPortal {
  position: relative;
  z-index: 99999;

  .slide-pane__header {
    flex: 0 0 54px;
    height: 54px;
    background: #FFF;
    color: var(--color_text);
  }

  .slide-pane__close {
    margin-left: 0;
    padding: 15px;
  }

  .slide-pane__title-wrapper {
    padding: 15px 0;
    margin-left: 0;

    .slide-pane__title { font-weight: 700; }
  }

  .slide-pane__content { padding: 15px; }
}

.ReactModalPortal .slide-pane__content,
.program-wrapper .program-filter-wrapper {
  .search-filter > label {
    color: var(--color_text);
    font-weight: 700;
  }

  .search-filter {
    & > div:not(.dates-wrapper) div:last-child .button { margin-bottom: 0; }

    .button.button-small {
      white-space: break-spaces !important;
      text-align: left;
    }

    /* Thematics arrow style */
    .btn.btn-link {
      padding: 0!important;
      border: none !important;
      margin-bottom: 0;
    }

    & + .search-filter {
      border-top: 1px solid #ddd;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}
